import React from "react";
import { Link } from "gatsby";
import { Layout, Row, Col, BackTop } from "antd";
import { Helmet } from "react-helmet";
import Header from "../../components/common/header";
import CommonFooter from "../../components/common/footer";
import TutorialSider from "../../components/guide/tutorial-sider";

// import css from "../../styles/guide.css";

const TeamRoom = ({}) => (
  <div>
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Team Calendar Planner Tutorial - DayViewer</title>
        <meta
          name="description"
          content="Getting Started Guide for DayViewer Online Team Calendar that helps you plan and organize your tasks and time in a team. Use DayViewer for teamwork, as a team planner or project management"
        />
      </Helmet>
      <Header />
      <Layout style={{ minHeight: "100vh" }}>
        <BackTop />
        <TutorialSider />
        <Layout className="guide-body">
          <h1>Team Room - Let's Get Up &amp; Running</h1>
          <Row>
            <Col>
              <h3>Intro</h3>
              <p>
                When you need a central system to keep a track of team tasks,
                events, notes and other information, open up a team room and
                invite colleagues or staff. Anyone needing a shared diary
                planner system will benefit by keeping organized together as a
                team.
              </p>
              <p>
                Here we help you get started and show the main management areas
                and a bit of information.
              </p>
              <p>
                For Details how to open a team room and add members please go to{" "}
                <Link to="/guide-content/dayviewer-team">Team Room Guide</Link>
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="tutorial-steps">
                <h3>Step 1</h3>
                <p>
                  Hopefully by now you have created your team room and have now
                  a couple of members in your team. If not please go to the{" "}
                  <Link to="/guide-content/dayviewer-team">
                    Team Room Guide
                  </Link>{" "}
                  and start a trial on the Team Room
                </p>
              </div>
              <div className="tutorial-steps">
                <h3>Step 2</h3>
                <p>
                  Set the member roles accordingly - as an owner, you are
                  automatically an admin and have all privileges. This is all
                  done in the "Team Management" area found under "Settings" in
                  your team room
                </p>
                <div className="tutorial-images">
                  <img
                    src="https://dayviewer.s3-us-west-2.amazonaws.com/img/home/dvnext/tutorial/team-tutorial-set-roles.png"
                    alt="team room setup"
                    title="Team management area"
                  />
                </div>
              </div>
              <div className="tutorial-steps">
                <h3>Step 3</h3>
                <p>
                  Next we will create a task and assign it to your colleague
                </p>
                <div className="tutorial-images">
                  <video
                    preload="none"
                    controls="controls"
                    muted="muted"
                    // autoplay="autoplay"
                    // loop="loop"
                    width="100%"
                    name="Team Task Create and Assign"
                    src="https://dayviewer.s3-us-west-2.amazonaws.com/img/home/dvnext/tutorial/video/team-task-create-assign.mp4"
                  />
                </div>

                <p>
                  When you assign a task, the notification goes to that team.
                  Now when you go to your Team Room Calendar or other views, the
                  task will be present to view for all team members.
                </p>
              </div>
              <div className="tutorial-steps">
                <h3>Step 4</h3>
                <p>
                  Test out your team room with the other entries, especially
                  journal posts, notes and the other features just to get
                  familiar with the system.
                </p>
              </div>
              <div className="tutorial-steps">
                <h3> Step 5 </h3>
                <p>
                  The image below shows where we would make a comment on the
                  task we created
                </p>
                <div className="tutorial-images">
                  <img
                    src="https://dayviewer.s3-us-west-2.amazonaws.com/img/home/dvnext/tutorial/tutorial-team-comment.png"
                    alt="team comments"
                    title="Team comments"
                  />
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col>
              <h3>Summary</h3>
              <p>
                Hopefully you can see the benefits that by using DayViewer, your
                team will be able to work together and stay focussed. By going
                through the other tutorials, you and your team should be able to
                utilise the full potential of DayViewer and make it part of your
                work planner, scheduling and information organization solution
                and get your team to achieve more every day.
              </p>

              <Link to={`/tutorial-content/quick-start`}>
                Go through the main tutorial within your team room.
              </Link>
            </Col>
          </Row>
        </Layout>
      </Layout>
      <CommonFooter />
    </Layout>
  </div>
);

export default TeamRoom;
